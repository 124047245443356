<template>
  <div>
    <img class="transform translate-y-1 translate-x-1" src="@/assets/voucher-event.svg" alt="" />
    <div class="absolute z-30 top-1/2 sm:left-12 left-8  inline-block transform -translate-y-1/2">
      <div class="flex items-center">
        <img :src="tIm" class="w-14 h-14 sm:w-20 sm:h-20 rounded-full" alt="" />
        <div class="ml-8 text-xs w-60">
          <h2 class="sm:text-lg text-sm font-semibold truncate sm:w-64 w-40">{{tTt}}</h2>
          <p><span class="mr-2">
              <font-awesome-icon icon="fa-regular fa-calendar" size="lg" />
            </span>{{$moment(tDt).format("dddd, D MMMM YYYY")}}</p>
          <p><span class="mr-2">
              <font-awesome-icon icon="fa-solid fa-location-dot" />
            </span>{{tLc}}</p>
          <p><span class="mr-2">
              <font-awesome-icon icon="fa-regular fa-clock" size="lg" />
            </span>{{tTm}} WIB</p>
        </div>
      </div>
    </div>
    <div class="absolute z-40 bottom-1/4 right-16 cursor-pointer" @click="showDetailTicket()">
      <span class="px-2 py-px bg-yellow rounded-lg text-xs font-semibold">Detail</span>
    </div>
    <DetailTicketEvent ref="detailTicket"
    :dataRegis="dataRegis"
    :tTt="tTt"
    :tDt="tDt"
    :tTm="tTm"
    :tLc="tLc"
    />
  </div>
</template>
<script>
export default {
  name: 'CardCurrentTicket',
  props: {
    dataRegis: {
      type: Object,
      required: true,
    },
    tIm: {
      type: String,
      required: true,
    },
    tTt: {
      type: String,
      required: true,
    },
    tDt: {
      type: String,
      required: true,
    },
    tTm: {
      type: String,
      required: true,
    },
    tLc: {
      type: String,
      required: true,
    },
    tLc: {
      type: String,
      required: true,
    },
  },
  components: {
    DetailTicketEvent: () => import('@/components/layout/DetailTicketEvent.vue'),
  },
  methods: {
    showDetailTicket() {
      this.$refs.detailTicket.open()
    }
  }
}
</script>